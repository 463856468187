import React from "react";
import { graphql } from "gatsby";

import Favicons from "../components/favicons";
import Hero from "../components/hero";
import InfoRows from "../components/InfoRows";
import CTA from "../components/cta";
import { TopWave, BottomWave } from "../components/wave";

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      openGraph {
        title
        description
      }
      page {
        ...PageInfo
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      primaryColor {
        hex
      }
      secondaryColor {
        hex
      }
      title
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`;

const Page = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const page = data.page || data.route.page;

  const content = (page._rawContent || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null;
      switch (c._type) {
        case "infoRows":
          el = <InfoRows key={c._key} {...c} />;
          break;
        case "hero":
          el = <Hero key={c._key} {...c} />;
          break;
        case "ctaPlug":
          el = <CTA key={c._key} {...c} />;
          break;
        case "uiComponentRef":
          switch (c.name) {
            case "topWave":
              el = <TopWave />;
              break;
            case "bottomWave":
              el = <BottomWave />;
              break;
            default:
              break;
          }
          break;
        default:
          el = null;
      }
      return el;
    });

  const gradient = {
    from: (site.primaryColor && site.primaryColor.hex) || "#d3f4ed",
    to: (site.secondaryColor && site.secondaryColor.hex) || "#d1edf3"
  };

  const menuItems = page.navMenu && (page.navMenu.items || []);

  const pageTitle =
    (data.route && data.route.openGraph.title) ||
    (data.route && !data.route.useSiteTitle && page.title) ||
    null;

  const pageDescription = (data.route && data.route.openGraph.description) || null;

  return (
    <Layout navMenuItems={menuItems} textWhite={false}>
      <SEO
        title={pageTitle}
        description={pageDescription}
        bodyAttr={{
          class: "leading-normal tracking-normal gradient"
        }}
      />
      <Favicons />
      {gradient && gradient.from && gradient.to && (
        <style type="text/css">{`
          .gradient {
            background: linear-gradient(90deg, ${gradient.from} 0%, ${gradient.to} 100%);
          }
        `}</style>
      )}
      <div className="pt-20">{content}</div>
    </Layout>
  );
};

export default Page;
