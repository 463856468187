import React from "react";
import PortableText from "../components/portableText";
import CTALink from "./CTALink";
import { MainTitle } from "./heading";

const CTA = ({ label, title, body, ctas }) => (
  <section className="container mx-auto text-center py-6 mb-12 text-gray-900">
    <MainTitle content={title} />

    <p className="my-4 text-2xl leading-tight">
      <PortableText blocks={body} />
    </p>

    <div className="flex">
      {(ctas || []).map((c, i) => (
        <div className="flex-1 text-gray-700 text-center py-2">
          <CTALink
            key={`cta_${i}`}
            {...c}
            buttonActionClass="mx-auto ml-4 focus:outline-none focus:shadow-outline bg-gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg"
          />
        </div>
      ))}
    </div>
  </section>
);

export default CTA;
