import React from "react";
import PortableText from "./portableText";

import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import { MainTitle } from "./heading";

const maybeImage = illustration => {
  let img = null;
  if (
    illustration &&
    illustration.disabled !== true &&
    illustration.image &&
    illustration.image.asset
  ) {
    const fluidProps = getFluidGatsbyImage(
      illustration.image.asset._id,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = <img className="max-h-64 mx-auto" src={fluidProps.src} alt={illustration.image.alt} />;
  }
  return img;
};

const InfoRow = props => {
  const img = maybeImage(props.illustration);
  return (
    <div className={"flex flex-wrap pt-6"}>
      <div className={"flex flex-col justify-center p-6 w-full" + (img ? " sm:w-3/5" : "")}>
        {props.title && (
          <h3 className="text-2xl text-gray-900 font-bold leading-none mb-3">{props.title}</h3>
        )}
        <p className="text-gray-700 cms-content">
          <PortableText blocks={props.text} />
        </p>
      </div>
      {img && <div className={"p-6 w-full sm:w-2/5"}>{img}</div>}
    </div>
  );
};

const InfoRowFlipped = props => {
  const img = maybeImage(props.illustration);
  return (
    <div className={"flex flex-wrap pt-6 flex-col-reverse sm:flex-row"}>
      {img && <div className={"p-6 w-full sm:w-2/5"}>{img}</div>}
      <div className={"flex flex-col justify-center p-6 w-full" + (img ? " sm:w-3/5" : "")}>
        {props.title && (
          <h3 className="text-2xl text-gray-900 font-bold leading-none mb-3">{props.title}</h3>
        )}
        <p className="text-gray-700 cms-content">
          <PortableText blocks={props.text} />
        </p>
      </div>
    </div>
  );
};

const InfoRows = props => {
  const contentRows = (props.rows || [])
    .filter(r => !r.disabled)
    .map((r, i) => {
      return i % 2 === 0 ? <InfoRow key={r._key} {...r} /> : <InfoRowFlipped key={r._key} {...r} />;
    });

  return (
    <section className="bg-white py-8">
      <div className="container max-w-5xl mx-auto m-8">
        <MainTitle content={props.title} />
        {contentRows}
      </div>
    </section>
  );
};

export default InfoRows;
