import React from "react";

export const MainTitle = ({ content }) => (
  <>
    <h1 className="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-900 px-2">
      {content}
    </h1>
    <div className="w-full mb-4">
      <div className="h-1 mx-auto gradient w-64 my-0 py-0 rounded-t"></div>
    </div>
  </>
);
